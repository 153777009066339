@import url("npm:katex/dist/katex.css");

html {
  font-family: sans-serif;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  min-width: 0;
}
